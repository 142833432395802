export default (imageUrl: string) => {
  if (!imageUrl && typeof (imageUrl) !== 'string') {
    return undefined
  }
  if (imageUrl.startsWith('/')) {
    return imageUrl
  }
  const path = encodeURIComponent(imageUrl)
  const refresh = 2592000
  return `https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?url=${path}&container=focus&refresh=${refresh}`
}
