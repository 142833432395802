/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import moment from 'moment'
import { Link } from 'gatsby'

import BaseLayout from './BaseLayout'
import SearchWidget from '../components/widgets/search/search'
import TagWidget from '../components/widgets/tag/tag'
import imgCache from '../util/ImgCache'

const my1 = css`
  margin-top: 1em;
  margin-bottom: 1em;
`

const ListingLayout = ({ nodes, tagName, pagination, children }: {
  nodes: any[]
  tagName?: string
  pagination: any
  children: any
}) => (
  <BaseLayout>
    { tagName ? (
      <h1 className="title" css={my1}>{ tagName }</h1>
    ) : null }
    { children }
    <div id="colorlib-main">
      <section className="ftco-section ftco-no-pt ftco-no-pb">
        <div className="container">
          <div className="row d-flex">
            <div className="col-xl-8 px-md-5 py-5">
              <div className="row pt-md-4">
              { nodes.map((el) => {
                const fm = el.frontmatter
                const title = fm.title || el.fields.slug
                const tags = fm.tag
                return (
                  <div key={ title } className="col-md-12">
                    <div className="blog-entry-2 ftco-animate fadeInUp ftco-animated">
                      <Link className={fm.image ? 'img' : 'd-none'} to={ `/posts${el.fields.slug}` }>
                        {fm.image ? (
                          <img className="w-100 img-fluid lazyload blur-up" data-src={ imgCache(fm.image) } alt={title} src="/media/image-placeholder.webp" />
                        ) :  null}
                      </Link>
                      <div className="text m-2">
                        <h3 className="mb-4"><Link to={ `/posts${el.fields.slug}` }>{ title }</Link></h3>
                        <div className="mb-4" dangerouslySetInnerHTML={{ __html: el.excerpt }}></div>
                        <div className="author mb-4 d-flex align-items-center">
                          <div className="ml-3 info">
                            <span>{ moment(el.correctedDateEpoch).format('LL') }</span>
                            { tags.length ? (
                              <h3 className="category">
                                { tags.map((tag: string) => {
                                  return (
                                    <Link key={tag} to={ `/tag/${tag.toLowerCase()}` }>
                                      <span className="mr-1"><i className="icon-tag"></i> {tag}</span>
                                    </Link>
                                  )
                                })
                                }
                              </h3>
                            ) : null}
                          </div>
                          <div className="ml-3">
                          </div>
                        </div>
                        {/* <div className="meta-wrap d-md-flex align-items-center">
                          <div className="half order-md-last text-md-right">
                          </div>
                          <div className="half">
                            <p><Link to={ `/posts${el.fields.slug}` } className="btn btn-primary p-2 px-xl-3 py-xl-2">Continue Reading</Link></p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                )
              }) }
              {
                nodes.length == 0 ? (
                  <h1>No post found!</h1>
                ) : null
              }
              </div>

              <div className="row">
                <div className="col">
                  <div className="block-27">
                  { pagination }
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 sidebar ftco-animate fadeInUp ftco-animated bg-light pt-5">
                <div className="sidebar-box pt-md-4">
                  <SearchWidget></SearchWidget>
                </div>
                <div className="sidebar-box ftco-animate fadeInUp ftco-animated">
                  <TagWidget></TagWidget>
                </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </BaseLayout>
)

export default ListingLayout
